<template>
  <v-dialog
    :value="isShown"
    :max-width="isEditing ? '70%' : '600px'"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        <div class="d-flex justify-space-between flex-grow-1">
          <div>
            <v-btn
              v-if="isGisDrawing"
              small
              icon
              :ripple="false"
              @click="openGisViewer"
            >
              <v-icon> mdi-map </v-icon>
            </v-btn>
          </div>
          <span>
            {{ titleText }}
          </span>
          <span></span>
        </div>
      </v-card-title>
      <v-divider />
      <div class="d-flex">
        <v-form ref="create-rfi-form" class="flex-1">
          <div class="px-10 pb-5">
            <v-row class="d-flex">
              <v-col class="pa-0 pl-3" cols="8">
                <ant-input
                  :label="$t('general.title') | capitalize"
                  class="mr-2"
                  style="flex: 1"
                >
                  <template #input-field>
                    <div class="d-flex">
                      <v-text-field
                        v-model="rfi.title"
                        :disabled="!canUpdate"
                        :placeholder="$t('general.title')"
                        :rules="[rules.required]"
                        dense
                        filled
                        hide-details
                      />
                    </div>
                  </template>
                </ant-input>
              </v-col>
              <v-col class="pa-0 pr-3" cols="4">
                <ant-input
                  :label="$t('general.type') | capitalize"
                  class="mr-2"
                >
                  <template #input-field>
                    <v-select
                      v-model="rfi.options.custom_1"
                      :disabled="!canUpdate"
                      :items="['design', 'coordination', 'quality']"
                      :placeholder="$t('general.type')"
                      :rules="[rules.required]"
                      dense
                      filled
                      hide-details
                    >
                    </v-select>
                  </template>
                </ant-input>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col class="pa-0 pl-3" cols="6">
                <ant-input
                  :label="$t('general.priority') | capitalize"
                  class="mr-2"
                  style="flex: 1"
                >
                  <template #input-field>
                    <v-select
                      v-model="rfi.priority"
                      :disabled="!canUpdate"
                      :items="['low', 'normal', 'high', 'urgent']"
                      :placeholder="$t('general.priority') | capitalize"
                      :rules="[rules.required]"
                      dense
                      filled
                      hide-details
                    >
                      <template #selection="{ item, index }">
                        <v-icon :color="getPriorityColor(item)" class="mr-2"
                          >{{ getPriorityIcon(item) }}
                        </v-icon>
                        {{ item }}
                      </template>
                      <template #item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-icon>
                            <v-icon :color="getPriorityColor(item)"
                              >{{ getPriorityIcon(item) }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </template>
                </ant-input>
              </v-col>
              <v-col class="pa-0 pr-3" cols="6">
                <ant-input :label="`${$t('system.tasks.dueDate')}`">
                  <template #input-field>
                    <v-menu
                      v-model="menus.due_date"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="rfi.due"
                          :disabled="!canUpdate"
                          :placeholder="today"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          hide-details
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="rfi.due"
                        no-title
                        scrollable
                        @input="menus.due_date = false"
                      />
                    </v-menu>
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row cols="12">
              <task-label
                :editing="canUpdate"
                :labels="rfi.labels"
                :project-id="rfi?.project?.id"
                class="px-3 mt-5"
                @on-change="onLabelChange"
              />
            </v-row>
            <ant-input :label="`${$t('system.tasks.executedBy')}`">
              <template #input-field>
                <transition mode="out-in" name="simple-fade">
                  <v-edit-dialog v-if="!rfi.assigned_user" persistent>
                    <v-icon class="ant-icon"> mdi-plus</v-icon>
                    <template #input>
                      <v-combobox
                        v-model="rfi.assigned_user"
                        :disabled="!rfi.project"
                        :items="users"
                        item-text="email"
                        item-value="id"
                        placeholder="email"
                        return-object
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                  <div v-else class="d-flex align-center">
                    <v-avatar
                      :color="
                        !canUpdate ? 'var(--v-secondary-lighten3)' : 'primary'
                      "
                      class="mr-2 white--text"
                      size="30"
                    >
                      {{
                        `${rfi.assigned_user.firstname.charAt(
                          0
                        )}${rfi.assigned_user.lastname.charAt(0)}`
                      }}
                    </v-avatar>
                    <div
                      :style="{
                        fontSize: '12px',
                        color: !canUpdate
                          ? 'var(--v-secondary-lighten3)'
                          : 'inherit',
                      }"
                      class="d-flex align-center"
                    >
                      {{ rfi.assigned_user.email }}
                      <v-icon
                        v-if="canUpdate"
                        :class="[canUpdate ? 'ant-icon' : 'icon-disabled']"
                        small
                        @click="unsetSubscribedUser"
                        >mdi-close
                      </v-icon>
                    </div>
                  </div>
                </transition>
              </template>
            </ant-input>
            <ant-input label="Sbscode">
              <template #input-field>
                <v-combobox
                  :disabled="
                    !!sbsObject ||
                    rfi.options.custom_2 === 'forge' ||
                    !canUpdate
                  "
                  :items="sbsRecords"
                  :loading="sbsLoading"
                  :value="rfi.sbsObject"
                  clearable
                  dense
                  filled
                  hide-details
                  item-text="code"
                  item-value.sync="code"
                  placeholder="Sbscode"
                  @change="onCodeChange"
                >
                  <template #item="{ item }">
                    <div class="d-flex">
                      {{ item.label ? item.label : '-' }}
                      <v-divider class="mx-2" vertical />
                      <div class="font-italic fs-12">{{ item.code }}</div>
                    </div>
                  </template>
                </v-combobox>
              </template>
            </ant-input>
            <slot name="add-task-options" />
            <ant-input
              :label="`${$t('general.description')}` | capitalize"
              is-optional
            >
              <template #input-field>
                <v-textarea
                  v-model="rfi.description"
                  :disabled="!canUpdate"
                  :placeholder="$t('system.tasks.addDescription')"
                  :rules="[rules.maxLength]"
                  filled
                  hide-details
                />
              </template>
            </ant-input>
            <ant-input
              v-if="authenticatedUser?.id === task?.assigned_to?.id"
              label="Status"
            >
              <template #input-field>
                <div class="d-flex">
                  <v-btn
                    v-if="rfi.status === 'open'"
                    class="mr-2"
                    color="warning"
                    elevation="0"
                    outlined
                    small
                    tile
                    @click="cancelTask"
                    >cancel
                  </v-btn>
                  <v-btn
                    :color="rfi.status === 'open' ? 'success' : ''"
                    elevation="0"
                    outlined
                    small
                    tile
                    @click="closeOrOpenTask"
                    >{{ rfi.status === 'open' ? 'complete' : 'reopen' }}
                  </v-btn>
                </div>
              </template>
            </ant-input>
            <v-spacer />
          </div>
        </v-form>

        <task-activities
          v-if="task"
          :task="task"
          class="pa-2 flex-1"
          style="max-height: 585px"
        />
      </div>

      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          :disabled="rfiRequestStatus === 'loading'"
          color="error"
          small
          text
          @click.stop="closeDialog"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!canUpdate"
          :loading="rfiRequestStatus === 'loading'"
          color="primary"
          elevation="0"
          small
          @click.stop="onRfiSubmit"
        >
          {{ isEditing ? $t('general.save') : $t('general.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AntInput from '@/components/AntInput.vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { getProjectMembersWithTaskPermissions } from '@/services/api/projects.api';
import { getSbsRecords } from '@/services/api/sbs.api';
import TaskHelper from '@/services/task-helper';
import TaskActivities from '@/components/Tasks/TaskActivities.vue';
import TaskLabel from '@/components/Tasks/TaskLabel.vue';

export default {
  name: 'RfiDialog',
  components: { TaskLabel, TaskActivities, AntInput },
  props: {
    parent: {
      type: String,
      default: null,
    },
    returnCreate: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      required: true,
    },
    sbsObject: {
      type: Object,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    metadata: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isPrivate: false,
      today: moment().format('YYYY-MM-DD'),
      rfi: {
        priority: null,
        title: null,
        labels: [],
        options: {
          type: 'rfi',
        },
        sbsObject: null,
      },
      users: [],
      menus: {
        start_date: false,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
      rfiRequestStatus: '',
      sbsRecords: [],
      sbsLoading: false,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser', 'selectedLicense', 'task', 'tasksRfi']),
    ...mapGetters('gisViewer', {
      baseWkid: 'baseWkid',
    }),
    isGisDrawing() {
      return this.task?.task_type?.rfi_module === 'gis-drawing';
    },
    isEditing() {
      return !!this.task;
    },
    canUpdate() {
      return (
        !this.isEditing ||
        ((this.$can('update', 'rfi') ||
          this.authenticatedUser?.id === this.task?.assigned_to?.id) &&
          this.task?.status === 'open')
      );
    },
    titleText() {
      const mode = this.isEditing
        ? this.canUpdate
          ? this.$t('general.edit')
          : null
        : this.$t('general.create');
      const number = this.task?.number ? `(${this.task?.number})` : null;
      return [mode, 'RFI', number].filter(Boolean).join(' ');
    },
  },
  watch: {
    async isShown(value) {
      if (!value) {
        this.closeDialog();
        return;
      }
      if (!this.project) {
        this.closeDialog();
        this.$store.commit('showNotification', {
          content: this.$t('system.tasks.notifications.noProject'),
          color: 'error',
        });
        return;
      }

      this.rfi.project = this.project;
      this.rfi.priority = 'normal';

      if (this.sbsObject) {
        this.rfi.sbsObject = this.sbsObject;
      }
      if (this.isEditing) {
        this.rfi = {
          title: this.task.title,
          project: this.task.task_project,
          priority: this.task.priority,
          description: this.task.description,
          assigned_user: this.task.assigned_to,
          due: moment(this.task.due).format('YYYY-MM-DD'),
          sbsObject: this.task.sbscode,
          license: this.task.license,
          status: this.task.status,
          labels: this.task.labels,
          options: {
            type: 'rfi',
            custom_1: this.task.task_type.rfi_type,
            custom_2: this.task.task_type.rfi_module,
            custom_3: this.task.task_type.rfi_state,
            custom_4: this.task.task_type.rfi_x,
            custom_5: this.task.task_type.rfi_y,
            custom_6: this.task.task_type.rfi_z,
            custom_8: this.task.task_type.rfi_relative_coords,
          },
        };
      }

      this.users = await getProjectMembersWithTaskPermissions(
        this.rfi.project.id
      );
      this.sbsLoading = true;
      this.sbsRecords = await getSbsRecords(this.rfi.project.id, {});
      this.sbsLoading = false;
    },
    'rfi.sbsObject.code': {
      handler(v, oldV) {
        if (!oldV && v) {
          this.onCodeChange(v);
        }
      },
      deep: true,
      immediate: true,
    },
    options: {
      async handler(options) {
        if (options) {
          const coords = {
            x: options?.x,
            y: options?.y,
            z: options?.z,
          };
          if (
            options?.spatialReference &&
            options?.spatialReference?.wkid !== this.baseWkid
          ) {
            const point = await this.$store.dispatch(
              'gisViewer/convertPointCoordinates',
              {
                x: coords?.x,
                y: coords?.y,
                z: coords?.z,
                spatialReference: options?.spatialReference,
              }
            );
            if (point) {
              coords.x = point.x;
              coords.y = point.y;
              coords.z = point.z;
            }
          }
          this.rfi.options.custom_2 = options.module;
          this.rfi.options.custom_4 = options?.x;
          this.rfi.options.custom_5 = options?.y;
          this.rfi.options.custom_6 = options?.z ? coords.z + 0.1 : undefined;
          this.rfi.options.custom_8 = JSON.stringify(
            options.forgeRelativeCoords
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onCodeChange(e) {
      typeof e === 'object'
        ? (this.rfi.sbsObject = e)
        : (this.rfi.sbsObject = { code: e });
    },
    unsetSubscribedUser() {
      if (this.canUpdate) {
        this.rfi.assigned_user = null;
      }
    },
    closeDialog() {
      if (this.rfiRequestStatus !== 'loading') {
        this.$emit('closeDialog');
        this.$refs['create-rfi-form'].reset();
        this.rfiRequestStatus = '';
        this.rfi = {
          priority: null,
          title: null,
          options: {
            type: 'rfi',
          },
          sbsObject: null,
        };
      }
    },
    async onRfiSubmit() {
      if (this.$refs['create-rfi-form'].validate()) {
        if (!this.rfi.title) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.fillTitle'),
            color: 'error',
          });
          return;
        }
        if (!this.rfi.assigned_user) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.addUser'),
            color: 'error',
          });
          return;
        }

        let body = {
          parent: this.parent,
          project: this.rfi.project.id,
          title: this.rfi.title,
          priority: this.rfi.priority,
          description: this.rfi.description,
          assigned_to: this.rfi.assigned_user.id || this.rfi.assigned_to.id,
          due: moment(this.rfi.due).format('YYYY-MM-DD HH:mm:ss'),
          sbscode: this.rfi?.sbsObject?.code,
          license: this.selectedLicense.id,
          labels: this.rfi?.labels?.map((l) => l.id),
          options: this.rfi.options,
        };

        if (this.metadata?.data) {
          body.options.metadata = JSON.stringify(this.metadata.data);
        }

        if (this.returnCreate) {
          this.$emit('createRfi', body);
        } else {
          if (!this.isEditing) {
            this.rfiRequestStatus = 'loading';

            this.$store
              .dispatch('createTask', { body })
              .then((task) => {
                this.rfiRequestStatus = 'success';
                this.$store.commit('showNotification', {
                  content: this.$t('system.tasks.notifications.createRfi', {
                    title: task.title,
                  }),
                  color: 'success',
                });
                this.$emit('changeRfi');
                this.closeDialog();
              })
              .catch((error) => {
                this.rfiRequestStatus = 'error';
              });
          } else {
            this.rfiRequestStatus = 'loading';

            this.$store
              .dispatch('updateTask', { taskId: this.task.id, body })
              .then((task) => {
                this.rfiRequestStatus = 'success';
                this.$store.commit('showNotification', {
                  content: this.$t('system.tasks.notifications.updateRfi', {
                    title: task.title,
                  }),
                  color: 'success',
                });
                this.$emit('changeRfi');
                this.closeDialog();
              })
              .catch((error) => {
                this.rfiRequestStatus = 'error';
              });
          }
        }
      }
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    async cancelTask() {
      await this.$store.dispatch('cancelTask', this.task.id);
      this.rfi.status = 'canceled';
      this.updateRfiStatuses();
    },
    async closeOrOpenTask() {
      if (this.task.status === 'open') {
        await this.$store.dispatch('closeTask', this.task.id);
        this.rfi.status = 'closed';
      } else {
        await this.$store.dispatch('reopenTask', this.task.id);
        this.rfi.status = 'open';
      }
      this.updateRfiStatuses();
    },
    updateRfiStatuses() {
      this.$store.dispatch('gisViewer/loadRfiLayer', this.tasksRfi);
    },
    onLabelChange(labels) {
      this.rfi.labels = labels;
    },
    openGisViewer() {
      const rfiId = this.task.id;
      if (this.isGisDrawing) {
        return this.$store.dispatch('gisViewer/goTo', { rfiId });
      }

      const x = this.task.task_type.custom_4 || this.task.task_type.rfi_x;
      const y = this.task.task_type.custom_5 || this.task.task_type.rfi_y;
      if (x && y) {
        return this.$store.dispatch('gisViewer/goTo', { x, y });
      }

      if (rfiId) {
        return this.$store.dispatch('gisViewer/goTo', { rfiId });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-disabled {
  &:hover {
    cursor: default;
  }
}
</style>
